import _ from 'underscore';

import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';

// globale Variablen, Event Handlers und Services bereitstellen...
window.myVars = window.myVars || {};
window.myHandlers = window.myHandlers || {};
window.myHandlers.filterEinAustritte = filterEinAustritte;
window.myHandlers.filterPGS = filterPGS;
window.myHandlers.filterBereich = filterBereich;
window.myHandlers.erstelleMitarbeiterListe = erstelleMitarbeiterListe;
window.myHandlers.addInputRow = addInputRow;
window.myHandlers.toggleLoader = toggleLoader;
window.myHandlers.searchMA = searchMA;
window.myHandlers.sortListePersonalnummer = sortListePersonalnummer;
window.myHandlers.sortListeNachname = sortListeNachname;
window.myVars.ListeSortiertNachname = true;
window.myVars.ListeSortiertPersonalnummer;
/**
 *  Geht ein Objekt durch und befüllt alle Felder mit Values aus der Oberfläche bzw. eines HTML Elements (ähnlich zu der displayValues Funktion)
 */
function collectValues(dataObject, prefix, htmlDiv) {
	// eslint-disable-next-line no-restricted-syntax
	for (const property in dataObject) {
		// only collect if its normal values (no objects or arrays), if the value is null, then we can overwrite it.
		if ((typeof dataObject[property] !== 'object' && !Array.isArray(dataObject[property])) || dataObject[property] === null) {
			const input = htmlDiv.querySelector(`[aria-label="${prefix}${property}"]`);
			if (input) {
				switch (input.type) {
					// die Checkbox hat ihren True/False Wert nicht unter .value sondern unter .checked gespeichert.
					case 'checkbox':
						dataObject[property] = input.checked;
						break;
					case 'number':
						dataObject[property] = parseFloat(input.value);
						break;
					case 'select-one':
						// Wir wollen beim Select unterscheiden ob wir eine Zahl oder String wählen
						if (input.classList.contains('select-number')) {
							dataObject[property] = parseFloat(input.value);
						} else {
							dataObject[property] = input.value;
						}
						break;
					default:
						dataObject[property] = input.value;
						break;
				}
			}
		} else if (typeof dataObject[property] === 'object' && !Array.isArray(dataObject[property])) {
			// Wir rufen rekursiv die Funktion auf "unter Objekte" auf, die keine Arrays sind
			collectValues(dataObject[property], prefix, htmlDiv);
		}
	}
}

// Befüllt die Felder des Unternehmens bzw. der Betriebsstätte
function displayValues(obj, stack, htmlElement = document.body, prefix = '') {
	// eslint-disable-next-line no-restricted-syntax
	for (const property in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, property)) {
			// Falls das Attribut ein Objekt ist, dann ruf displayValues auf diesen Objekt auf.
			if (typeof obj[property] === 'object' && obj[property] !== null) {
				displayValues(obj[property], `${stack}.${property}`, htmlElement, prefix);
			} else {
				try {
                    const element = htmlElement.querySelector(`[aria-label='${prefix}${property}']`);
					if (element !== null) {
						switch (element.type) {
							case 'text':
								if (element.classList.contains('text-date') && obj[property] !== '' && obj[property] !== null) {
									element.value = dayjs(obj[property]).format('DD.MM.YYYY');
									element.title = dayjs(obj[property]).format('DD.MM.YYYY');
								} else if (element.classList.contains('text-date-short') && obj[property] !== '' && obj[property] !== null) {
									element.value = dayjs(obj[property]).format('DD.MM.YY');
									element.title = dayjs(obj[property]).format('DD.MM.YY');
								} else {
									element.value = obj[property];
									element.title = obj[property];
								}
								break;
							case 'email':
								element.value = obj[property];
								break;
							case 'textarea':
								element.value = obj[property];
								break;
							case 'number':
								element.value = obj[property];
								break;
							case 'select-one':
								if (property === 'GesetzlicheUV') {
									element.value = obj[property];
								} else {
									element.value = obj[property];
								}
								break;
							case 'date':
								if (obj[property] !== '' && obj[property] !== null) {
									element.value = dayjs(obj[property]).format('YYYY-MM-DD');
								} else {
									element.value = '';
								}
								break;
							case 'time':
								element.value = obj[property];
								break;
                            case 'checkbox':
                                element.checked = obj[property];
                                break;
							case undefined:
								element.innerText = `${obj[property]}`;
								// Result Output Formatierung für Euro Werte:
								if (element.classList.contains('output_euro')) {
									const euroWert = convertNumberToEuro(obj[property]);
									element.innerText = euroWert;
								}
								if (element.classList.contains('output_integer')) {
									element.innerText = obj[property];
								}
								if (element.classList.contains('output_zeitraum')) {
									element.innerText = dayjs(obj[property]).format('YYYY/MM');
								}
								if (element.classList.contains('text-date-short') && obj[property] !== '' && obj[property] !== null) {
									element.innerText = dayjs(obj[property]).format('DD.MM.YY');
								}
								if (element.classList.contains('text-date') && obj[property] !== '' && obj[property] !== null) {
									element.innerText = dayjs(obj[property]).format('DD.MM.YYYY');
								}
								if (element.classList.contains('text-date-time') && obj[property] !== '' && obj[property] !== null) {
									element.innerText = dayjs(obj[property]).format('DD.MM.YY, HH:mm');
								}
								if (element.classList.contains('output_number')) {
									element.innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(obj[property]);
								}
								break;
							default:
								element.innerText = '';
								break;
						}
					}
				} catch (e) {
					console.log(e);
				}
			}
		}
	}
}

// Debounce, Funktionsaufruf (cb) wird erst getätigt nacht einem bestimmten delay, z.B. 0.5 Sekunden
function debounce(cb, delay = 500) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            cb(args);
        }, delay);
		// Falls es sich um ein Mitarbeiterobjekt handelt, merken wir uns die timout id
		if (_.has(args[0], 'Personalien')) {
			window.myVars.lastDebounceId = timeout;
		}
    };
}

// Füllt ein Select Element mit einer bestimmten Liste an Optionen
function fuelleSelectOptionen(select, liste, valueKey, textKey, hasEmptyOption = false, emptyOptionText = '') {
	select.innerHTML = '';
	if (hasEmptyOption) {
		const emptyOption = document.createElement('option');
		emptyOption.value = '';
		emptyOption.innerText = emptyOptionText;
		select.appendChild(emptyOption);
	}
	liste.forEach((item) => {
		const option = document.createElement('option');
		option.value = item[valueKey];
		// Falls wir mehr als ein Attribut anzeigen wollen:
		if (_.isArray(textKey)) {
			textKey.forEach((text, index) => {
				option.innerText += index === 0 ? '' : ',\u00A0';
				option.innerText += item[text];
			});
		} else {
			option.innerText = item[textKey];
		}
		select.appendChild(option);
	});
}

// erstelle eine Gruppe von Optionen für ein Select.
function erstelleOptionGroup(titel, liste, valueKey, textKey) {
    const optGroup = document.createElement('optgroup');
    optGroup.label = titel;
    liste.forEach((item) => {
        const option = document.createElement('option');
        option.value = item[valueKey];
        // Falls wir mehr als ein Attribut anzeigen wollen:
        if (_.isArray(textKey)) {
            textKey.forEach((text, index) => {
                option.innerText += index === 0 ? '' : ',\u00A0';
                option.innerText += item[text];
            });
        } else {
            option.innerText = item[textKey];
        }
        optGroup.appendChild(option);
    });
    return optGroup;
}

// Befüllt Input Listen, wie die Bereiche, Posten, Lohnarten, Bezüge, etc.
function displayArray(array, prefix, panelHTML = document.body, bsID = '') {
	// Entferne alte Elemente falls vorhanden...
	const oldElements = panelHTML.querySelectorAll(`.${prefix}zeile:not(.header)`);
	oldElements.forEach((elem) => elem.remove());
    const template = panelHTML.querySelector(`[${prefix}template]`);
    // Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
    const referenceElement = panelHTML.querySelector(`[${prefix}button]`);
    array.forEach((element) => {
        neueArrayRow(template, referenceElement, element, prefix, bsID);
    });
}

/**
 * Erstellt eine neue Zeile in der Tabelle und zeigt diese an
 * @param {*} template template der Zeile
 * @param {*} referenceElement HTML Element als Ort wo die neue Zeile hinzugefügt wird
 * @param {*} element Array Element, z.B. ein Posten aller Posten
 * @param {*} prefix gibt an um welche Daten es sich handeln
 * @param {*} bsID optionale BetriebsstätteID
 */
function neueArrayRow(template, referenceElement, element, prefix, bsID = undefined) {
	// wir wollen die BetriebsstätteID auch in die Zeile nehmen, falls notwendig
	const suffix = !bsID ? '' : `-${bsID}`;
	const newRow = template.content.cloneNode(true).children[0];
	const rowID = element._id || element.id;
	// add the ID to the row and button
	newRow.id = prefix + rowID + suffix;
	newRow.setAttribute('aria-label', rowID);
	if (newRow.querySelector(`[aria-label="${prefix}verwenden"]`)) {
		newRow.querySelector(`[aria-label="${prefix}verwenden"]`).id = element._id;
	}
	if (newRow.querySelector(`[aria-label="${prefix}entfernen"]`)) {
		newRow.querySelector(`[aria-label="${prefix}entfernen"]`).id = element._id;
	}
	// Urlaubsantraege Aktionsbuttons mit id bestücken
	if (prefix === 'ma-ua-') {
		newRow.querySelectorAll(`[aria-label="${prefix}aktion"]`).forEach((btn) => { btn.id = element._id; });
	}
	// Wir wollen die Bereiche auswählen können in der Postenliste der Betriebsstätte
	if (prefix === 'bs-input-posten-') {
		const postenSelect = newRow.querySelector('[aria-label="bs-input-posten-BereichID"]');
		const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
		fuelleSelectOptionen(postenSelect, bereiche, '_id', 'Bereich');
	}
	if (prefix === 'bw-intern-' || prefix === 'bw-extern-') {
		const rollenFeld = newRow.querySelector(`[aria-label="${prefix}neolohnRoles"]`);
		const rollen = element.neolohnRoles;
		rollen.forEach((role, index) => {
			rollenFeld.value += role;
			rollenFeld.title += role;
			if (index < rollen.length - 1) {
				rollenFeld.value += ', ';
				rollenFeld.title += ', ';
			}
		});
		const bereicheFeld = newRow.querySelector(`[aria-label="${prefix}bereiche"]`);
		const unternehmen = stammdatenService.unternehmensobjekt;
		// check ob bereichIds hinterlegt sind, sonst behandle es wie ein leeres Array.
		const bereiche = _.isArray(element.attributes?.bereichId) ? element.attributes.bereichId : [];
		bereiche.forEach((bereichId, index) => {
			const bereich = unternehmen.Bereiche.find((b) => _.isEqual(b._id, bereichId))?.Bereich;
			bereicheFeld.value += bereich;
			bereicheFeld.title += bereich;
			if (index < bereiche.length - 1) {
				bereicheFeld.value += ', ';
				bereicheFeld.title += ', ';
			}
		});
		const betriebSelect = newRow.querySelector(`[aria-label="${prefix}betrieb"]`);
		fuelleSelectOptionen(betriebSelect, unternehmen.Betriebsstaette, '_id', 'BetriebsstaetteName', true, 'alle Betriebe');
		const betrieb = element.attributes?.betriebId;
		betriebSelect.value = betrieb !== undefined ? betrieb : '';
	}
	// Bei Qualifikationen wollen wir ein Select Feld haben aus allen Qualifikationen im Unternehmen
	if (prefix === 'ma-qu-') {
		const qualiSelect = newRow.querySelector('[aria-label="ma-qu-QualifikationID"]');
		const qualisVerwendet = stammdatenService.unternehmensobjekt.Qualifikationen.filter((q) => q.Verwenden);
		fuelleSelectOptionen(qualiSelect, qualisVerwendet, '_id', 'BezeichnungNeutral');
		// Für Effektivlohn muss das Effektivlohn/std Input aktiviert werden
		const effektivLohnInput = newRow.querySelector('[aria-label="ma-qu-Effektivlohn"]');
		if (element.Entlohnung !== 'Effektivlohn') {
			effektivLohnInput.disabled = true;
			effektivLohnInput.value = 0;
		} else {
			effektivLohnInput.disabled = false;
		}
		// Für Umsatzlohnarten muss das Beteiligung Input aktiviert werden
		if (['umsatzorientierterStundenlohn', 'Umsatzanteilslohn'].includes(element.Entlohnung)) {
			newRow.querySelector('[aria-label="ma-qu-UmsatzBeteiligungProzent"]').disabled = false;
		} else {
			newRow.querySelector('[aria-label="ma-qu-UmsatzBeteiligungProzent"]').disabled = true;
			newRow.querySelector('[aria-label="ma-qu-UmsatzBeteiligungProzent"]').value = 0;
		}
		// setze alle Einstellungsfelder auf disabled, wenn nicht verwendet.
		if (!element.Verwenden) {
			[...newRow.querySelectorAll('input[type="number"], select')].forEach((field) => { field.disabled = true; });
			newRow.querySelector('[aria-label="ma-qu-GueltigAb"]').disabled = true;
		} else {
			newRow.querySelector('[aria-label="ma-qu-GueltigAb"]').disabled = false;
		}
	}
	// Angewendeter EGFZ Schnittwert der Monatswerte in der Historie anzeigen.
	if (prefix === 'ma-jh-') {
		newRow.querySelector('[aria-label="ma-jh-EGFZBruttoStunde"]').innerText = convertNumberToEuro(element.Mitarbeiter.Monatswerte.EGFZBruttoStunde);
		newRow.querySelector('[aria-label="ma-jh-Gutzeit"]').innerText = element.Mitarbeiter.Monatswerte.GutzeitPlus - element.Mitarbeiter.Monatswerte.GutzeitMinus;
		if (element?.Abgeschlossen) {
			const abgeschlossenIcon = newRow.querySelector('.bi-lock-fill.invisible');
			abgeschlossenIcon.classList.remove('invisible');
		}
		if (element?.Vorwert) {
			newRow.classList.add('jh-vorwert');
			const removeIcon = newRow.querySelector('.bi-trash-fill.invisible');
			removeIcon.classList.remove('invisible');
			removeIcon.addEventListener('click', () => window.myHandlers.clickEntferneJournalVorwert(element.MitarbeiterID, element.Zeitraum));
		}
	}
	displayValues(element, '', newRow, prefix);
	if (prefix === 'ma-jh-') {
		newRow.querySelector('[aria-label="ma-jh-ZGesamt"]').innerText = convertNumberToEuro(element.Mitarbeiter.Ergebnis.Zuschlaege.ZGesamt);
	}
	if (prefix === 'ma-la-' || prefix === 'ma-nba-') {
		const zeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
		newRow.querySelector(`[aria-label="${prefix}Einmalig"]`).checked = element.Regelmaessig === true ? false : true;
		if (element.Verwenden && element.Regelmaessig !== true && element.Regelmaessig !== zeitraum) {
			newRow.querySelector(`[aria-label="${prefix}Verwenden"]`).checked = false;
		}
	}
	referenceElement.insertAdjacentElement('beforebegin', newRow);
}

// Fügt eine Inputzeile der Liste hinzu, z.B. für Posten
function addInputRow(thisElement, prefix) {
	const template = document.querySelector(`[${prefix}template]`);
	const newRow = template.content.cloneNode(true).children[0];
	if (prefix === 'bs-input-posten-') {
		const postenSelect = newRow.querySelector('[aria-label="bs-input-posten-BereichID"]');
		const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
		fuelleSelectOptionen(postenSelect, bereiche, '_id', 'Bereich');
	}
	thisElement.insertAdjacentElement('beforebegin', newRow);
	return newRow;
}

/**
 * Holt sich die Liste an Qualifikationen des Mitarbeiters inklusive Bezeichnung
 * @returns liste an Objekten (QualifikationID + Bezeichnung)
 */
function holeQualifikationenMitarbeiter() {
	const qualis = [];
	const ma = stammdatenService.aktuellerMitarbeiter;
	const unternehmen = stammdatenService.unternehmensobjekt;
	ma.Beschaeftigung[0].Verguetung.forEach((vg) => {
		const match = unternehmen.Qualifikationen.find((qu) => vg.QualifikationID === qu._id);
		if (match && vg.Verwenden) {
			qualis.push({ QualifikationID: match._id, Bezeichnung: match.BezeichnungNeutral, IstStandard: vg.IstStandardQualifikation });
		}
	});
	// nach standard sortieren
	return qualis.sort((a, b) => b.IstStandard - a.IstStandard);
}

/**
 * Holt sich die Liste an Posten des Mitarbeiters inklusive Bezeichnung
 * @returns liste an Objekten (PostenID + Bezeichnung)
 */
function holePostenMitarbeiter() {
	const postenListe = [];
	const ma = stammdatenService.aktuellerMitarbeiter;
	const betriebsstaette = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === ma.Beschaeftigung[0].BetriebsstaetteID);
	ma.Beschaeftigung[0].Posten.forEach((posten) => {
		const match = betriebsstaette.Posten.find((b) => posten.PostenID === b._id);
		if (match && match.Verwenden) {
			postenListe.push({ PostenID: match._id, Bezeichnung: match.Posten });
		}
	});
	return postenListe;
}

/**
 * Holt sich die Liste an Einsatzorten des Mitarbeiters inklusive Bezeichnung
 * @returns liste an Objekten (EinsatzortID + Bezeichnung)
 */
function holeEinsatzorteMitarbeiter() {
	const einsatzorte = [{ EinsatzortID: '', Bezeichnung: '-' }];
	const ma = stammdatenService.aktuellerMitarbeiter;
	const betriebsstaette = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === ma.Beschaeftigung[0].BetriebsstaetteID);
	ma.Beschaeftigung[0].Einsatzorte.forEach((einsatzort) => {
		const match = betriebsstaette.Einsatzorte.find((eo) => einsatzort.EinsatzortID === eo._id);
		if (match) {
			einsatzorte.push({ EinsatzortID: match._id, Bezeichnung: match.Bezeichnung });
		}
	});
	return einsatzorte;
}

// Konvertiert eine Zahl in das Euro Format.
function convertNumberToEuro(value) {
	return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
}

function formatNumber2Decimals(value) {
	return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

// Ein- und Ausblenden des Ladekreises
function toggleLoader() {
	const loader = document.getElementById('loader-div');
	if (loader.style.display === 'none' || loader.style.display === '') loader.style.display = 'block';
	else loader.style.display = 'none';
}

// Liest den Zeitraum aus dem Monatspicker aus
function getAktuellerZeitraum() {
    return dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
}

/**
 * Generiert die Mitarbeiter Liste in der Sidebar
 */
async function erstelleMitarbeiterListe(betriebsstaetteID = undefined) {
	// Falls keine betriebsstätte explizit angegeben wurde, nehmen wir die aus dem Stammdatenservice
	if (!betriebsstaetteID) {
		betriebsstaetteID = stammdatenService.aktuelleBetriebsstaette;
	}
	const maList = document.getElementById('malist');
	// Markierte Mitarbeiter zwischenspeichern...
	const markedMA = Array.from(maList.querySelectorAll('[aria-label="ma-control-check"]')).filter((ma) => ma.checked);
	maList.innerHTML = ''; // reset der Liste bei neuladen
	const zeitraum = getAktuellerZeitraum();
	let listeJson = await stammdatenService.holeMitarbeiterListe(betriebsstaetteID, zeitraum);
	// Filter aktiv
	// sortieren nach PNR
	if (window.myVars.ListeSortiertNachname === undefined && window.myVars.ListeSortiertPersonalnummer !== undefined) {
		listeJson.sort((a, b) => {
			if (a.Personalnummer === '') {
				return 1; // Move empty strings to the end
			} else if (b.Personalnummer === '') {
				return -1; // Move empty strings to the end
			} else {
				if (window.myVars.ListeSortiertPersonalnummer === true) {
					return parseInt(a.Personalnummer) - parseInt(b.Personalnummer); // Normal numeric comparison
				} else {
					return parseInt(b.Personalnummer) - parseInt(a.Personalnummer); // Normal numeric comparison
				}
			}
		});
	}
	// sortieren nach Nachname
	if (window.myVars.ListeSortiertNachname !== undefined && window.myVars.ListeSortiertPersonalnummer === undefined) {
		listeJson.sort((a, b) => {
			if (window.myVars.ListeSortiertNachname === true) {
				return a.Nachname.toLowerCase().localeCompare(b.Nachname.toLowerCase());
			} else {
				return b.Nachname.toLowerCase().localeCompare(a.Nachname.toLowerCase());
			}
		});
	}
	// sortieren nach Nachname
	if (document.getElementById('menu-ma-aktiv').value === '0') {
		listeJson = listeJson.filter((ma) => {
			// Falls es kein Eintrittsdatum gibt, wird er trotzdem angezeigt
			if (!ma.Eintrittsdatum || ma.Eintrittsdatum === '') {
				return true;
			}
			// Ansonsten ist der MA aktiv, falls das aktuelle Datum nach Eintritt ist oder (falls angegeben) zwischen Eintritt und Austritt liegt.
			let isAktiv = false;
			if (!ma.Austrittsdatum || ma.Austrittsdatum === '') {
				// falls es kein Austrittsdatum gibt, muss das Eintrittsdatum gleich oder nach dem aktuellen aktuellen Monatsdatum sein...
				isAktiv = dayjs(window.myVars.aktuellesMonatsDatum).isSameOrAfter(ma.Eintrittsdatum, 'month');
			} else {
				// Falls es das Austrittsdatum gibt, muss das Monatsdatum zwischen Ein- und Austrittsdatum liegen...
				isAktiv = dayjs(window.myVars.aktuellesMonatsDatum).isBetween(dayjs(ma.Eintrittsdatum), dayjs(ma.Austrittsdatum), 'month', '[]');
			}
			return isAktiv;
		});
	}
	const maControlTemplate = document.querySelector('[ma-control-template]');
	for (let i = 0; i < listeJson.length; i += 1) {
		const listItem = createMaControl(listeJson[i], maControlTemplate);
		maList.appendChild(listItem);
	}
	colorMaList();
	hightlightMA();
	// Gesetzte Häkchen wieder setzen
	markedMA.forEach((ma) => {
		const control = maList.querySelector(`[id="${ma.parentNode.parentNode.parentNode.id}"]`);
		// nur falls das Control noch exisitert können wir die Checkbox setzen.
		if (control) {
			control.querySelector('[aria-label="ma-control-check"]').checked = true;
		}
	});
	const pgsFilter = document.getElementById('ma-filter-pgs');
	if (pgsFilter.value !== '') {
		filterPGS(pgsFilter);
	}
	const infoFilter = document.getElementById('ma-filter-eintritt-austritt');
	if (infoFilter.value !== '') {
		filterEinAustritte(infoFilter);
	}
	const searchFilter = document.getElementById('ma-suchfeld');
	searchMA(searchFilter.value);
}

/**
 * Suchfilter für die Mitarbeiterliste, versteckt andere Mitarbeiter
 * @param {string} input nach dem gefiltern wird
 */
function searchMA(input) {
	const listElemente = document.querySelectorAll('.ma-select-item');
	// eslint-disable-next-line no-restricted-syntax
	for (const elem of listElemente) {
		if (elem.innerText.toUpperCase().indexOf(input.toUpperCase()) > -1) {
			elem.classList.remove('hidden');
		} else {
			// markierte Mitarbeiter nicht ausblenden
			const isMarked = elem.querySelector('[aria-label="ma-control-check"]').checked;
			if (!isMarked) {
				elem.classList.add('hidden');
			}
		}
	}
	document.getElementById('ma-filter-eintritt-austritt').value = '';
	document.getElementById('ma-filter-eintritt-austritt').classList.remove('filter_active');
	document.getElementById('ma-filter-pgs').value = '';
	document.getElementById('ma-filter-pgs').classList.remove('filter_active');
	document.querySelector('.malist_filtertitle').classList.remove('filter_active');
	colorMaList();
}

async function sortListeNachname(thisElement) {
	if (!window.myVars.ListeSortiertNachname) {
		window.myVars.ListeSortiertNachname = true;
		thisElement.classList.add('bi-sort-alpha-up');
		thisElement.classList.remove('bi-sort-alpha-down');
	} else {
		thisElement.classList.add('bi-sort-alpha-down');
		thisElement.classList.remove('bi-sort-alpha-up');
		window.myVars.ListeSortiertNachname = false;
	}
	window.myVars.ListeSortiertPersonalnummer = undefined;
	await erstelleMitarbeiterListe();
}

async function sortListePersonalnummer(thisElement) {
	if (!window.myVars.ListeSortiertPersonalnummer) {
		window.myVars.ListeSortiertPersonalnummer = true;
		thisElement.classList.add('bi-sort-numeric-up');
		thisElement.classList.remove('bi-sort-numeric-down');
	} else {
		thisElement.classList.add('bi-sort-numeric-down');
		thisElement.classList.remove('bi-sort-numeric-up');
		window.myVars.ListeSortiertPersonalnummer = false;
	}
	window.myVars.ListeSortiertNachname = undefined;
	await erstelleMitarbeiterListe();
}

/**
 * Highlightet den aktuellen Mitarbeiter in der Mitarbeiter Liste und zeigt diesen auch oben im Header an.
 */
function hightlightMA() {
	// altes hightlighting entfernen, falls vorhanden.
	const current = document.querySelector('.ma-select-active');
	if (current) {
		current.classList.remove('ma-select-active');
	}
	if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		// Falls der Mitarbeiter noch in der Liste ist, markieren wir ihn dort erneut.
		const maControl = document.getElementById(stammdatenService.aktuellerMitarbeiter._id);
		if (maControl !== null) {
			maControl.classList.add('ma-select-active');
		}
		const firma = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].BetriebsstaetteID).BetriebsstaetteName;
		const breadcrumbTitle = `${stammdatenService.unternehmensobjekt.UnternehmenName} | ${firma} | ${stammdatenService.aktuellerMitarbeiter.Personalien.Nachname}, ${stammdatenService.aktuellerMitarbeiter.Personalien.Vorname}`;
		const title = document.getElementById('title-betriebsstaette');
		title.innerText = breadcrumbTitle;
		title.title = breadcrumbTitle;
	}
}

// Hilft dabei die abwechselnde Hintergrundfarbe in der Liste beizubehalten nach Filterung
function colorMaList() {
	const listElemente = document.querySelectorAll('.ma-select-item:not(.hidden)');
	for (let i = 0; i < listElemente.length; i += 1) {
		if (i % 2 === 1) listElemente[i].classList.add('odd-color');
		else listElemente[i].classList.remove('odd-color');
	}
}

/**
 * Erstellt ein Mitarbeiter Listen Element mithilfe des Mitarbeiter Objekt aus der Mitarbeiterliste
 * @param {object} mitarbeiter vereinfachtes Mitarbeiterobjekt für die Liste.
 * @param {HTMLElement} template für das ma control
 * @returns HTML List Item Code für einen Mitarbeiter
 */
function createMaControl(mitarbeiter, template) {
	const newControl = template.content.cloneNode(true).children[0];
	newControl.id = mitarbeiter._id;
	newControl.addEventListener('click', () => window.myHandlers.selectMA(newControl.id));
	newControl.title = `${mitarbeiter.Nachname}, ${mitarbeiter.Vorname}`;
	newControl.querySelector('.ma-control-name').innerText = `${mitarbeiter.Nachname}, ${mitarbeiter.Vorname}`;
	newControl.querySelector('.pgs-pnr').innerText = `${mitarbeiter.PGS} - ${mitarbeiter.Personalnummer}`;
	mitarbeiter.Abgeschlossen ? newControl.querySelector('.ma-abgeschlossen-icon').classList.remove('hidden') : newControl.querySelector('.ma-abgeschlossen-icon').classList.add('hidden');
	mitarbeiter.NeueNachricht ? newControl.querySelector('.ma-nachricht-icon').classList.remove('hidden') : newControl.querySelector('.ma-nachricht-icon').classList.add('hidden');
	mitarbeiter.HasErrors ? newControl.querySelector('.ma-errors-icon').classList.remove('hidden') : newControl.querySelector('.ma-errors-icon').classList.add('hidden');
	mitarbeiter.Ueberlaenge ? newControl.querySelector('.ma-ueberlaenge-icon').classList.remove('hidden') : newControl.querySelector('.ma-ueberlaenge-icon').classList.add('hidden');
	mitarbeiter.Berechnet && !mitarbeiter.HasErrors ? newControl.querySelector('.ma-berechnet-icon').classList.remove('hidden') : newControl.querySelector('.ma-berechnet-icon').classList.add('hidden');
	newControl.querySelector('.ma-abgeschlossen-icon').addEventListener('click', (event) => window.myHandlers.monatEntsperrenMA(mitarbeiter._id, event));
	newControl.querySelector('.ma-errors-icon').addEventListener('click', () => window.myHandlers.showErrorsMA(mitarbeiter._id));
	const abrechnungStatusIcon = newControl.querySelector('.ma-lohnabrechnung-status-icon');
	if (mitarbeiter.Abweichung === false) {
		abrechnungStatusIcon.classList.remove('hidden');
		abrechnungStatusIcon.classList.add('bi', 'bi-check-circle-fill');
		abrechnungStatusIcon.title = 'Keine Abweichungen zur Probeabrechnung!';
	} else if (mitarbeiter.Abweichung === true) {
		abrechnungStatusIcon.classList.remove('hidden');
		abrechnungStatusIcon.classList.add('bi', 'bi-question-circle-fill');
		abrechnungStatusIcon.title = 'Abweichungen zur Probeabrechnung vorhanden!';
		abrechnungStatusIcon.addEventListener('click', () => window.myHandlers.showAbweichungMA(mitarbeiter._id, mitarbeiter.AbweichungDetails));
		newControl.querySelector('.ma-berechnet-icon').style.color = 'var(--custom-yellow)';
	} else {
		abrechnungStatusIcon.classList.add('hidden');
		abrechnungStatusIcon.title = '';
	}
	return newControl;
}

function setMenuInactive(activeclassname) {
	const current = Array.from(document.getElementsByClassName(activeclassname));
	current.forEach((elem) => elem.classList.remove(activeclassname));
}

function setMenuActive(element, activeclassname) {
	setMenuInactive(activeclassname);
	element.classList.add(activeclassname);
}

// Ladet alle Betriebstätten in die select-betriebsstaette Auswahl
async function fuelleBetriebSelect() {
	const select = document.getElementById('menu-betriebsstaette');
	// Reset wenn es schonmal befüllt wurde
	select.innerHTML = '';
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const aktiveBS = unternehmen.Betriebsstaette.filter((bs) => bs.Aktiv === true);
	const emptyOption = document.createElement('option');
	aktiveBS.forEach((bs) => {
		const option = document.createElement('option');
		option.innerText = bs.BetriebsstaetteName;
		option.value = bs._id;
		select.appendChild(option);
	});
	emptyOption.innerText = 'Alle Betriebe';
	emptyOption.value = '';
	select.appendChild(emptyOption);
}

/**
 * Filtert die Mitarbeiterliste nach Persongengruppenschlüssel (PGS).
 * Angehakte Mitarbeiter bleiben sichtbar.
 * @param {HTMLElement} thisElement Select der PGS
 */
async function filterPGS(thisElement) {
	const pgs = thisElement.value;
	// todo: Könnte man an der stelle auch optimieren, bzw. nicht neu laden...
	const zeitraum = getAktuellerZeitraum();
	const listeJson = await stammdatenService.holeMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette, zeitraum);
	let listePGSFilter = listeJson;
	if (pgs !== '') {
		listePGSFilter = listePGSFilter.filter((ma) => ma.PGS === pgs);
		document.getElementById('ma-filter-eintritt-austritt').value = '';
		document.getElementById('ma-filter-eintritt-austritt').classList.remove('filter_active');
		document.getElementById('ma-filter-bereich').value = '';
		document.getElementById('ma-filter-bereich').classList.remove('filter_active');
		thisElement.classList.add('filter_active');
		document.querySelector('.malist_filtertitle').classList.add('filter_active');
	} else {
		thisElement.classList.remove('filter_active');
		document.querySelector('.malist_filtertitle').classList.remove('filter_active');
	}
	const listeHTML = document.querySelectorAll('.ma-select-item');
	listeHTML.forEach((maHTML) => {
		// Falls der Mitarbeiter in der PGS Liste ist... oder schon markiert ist...
		if (listePGSFilter.some((maPGS) => maHTML.id === maPGS._id) || maHTML.querySelector('[aria-label="ma-control-check"]').checked) {
			// einblenden
			maHTML.classList.remove('hidden');
		} else {
			// ausblenden
			maHTML.classList.add('hidden');
		}
	});
	document.getElementById('ma-suchfeld').value = '';
	colorMaList();
}

/**
 * Filtert die Mitarbeiterliste nach Bereichen
 * Angehakte Mitarbeiter bleiben sichtbar.
 * @param {HTMLElement} thisElement Select des Bereichs
 */
async function filterBereich(thisElement) {
	const bereichID = thisElement.value;
	// todo: Könnte man an der stelle auch optimieren, bzw. nicht neu laden...
	const zeitraum = getAktuellerZeitraum();
	const listeJson = await stammdatenService.holeMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette, zeitraum);
	let listeBereichFilter = listeJson;
	if (bereichID !== '') {
		listeBereichFilter = listeBereichFilter.filter((ma) => ma.Bereiche.includes(bereichID));
		document.getElementById('ma-filter-eintritt-austritt').value = '';
		document.getElementById('ma-filter-eintritt-austritt').classList.remove('filter_active');
		document.getElementById('ma-filter-pgs').value = '';
		document.getElementById('ma-filter-pgs').classList.remove('filter_active');
		thisElement.classList.add('filter_active');
		document.querySelector('.malist_filtertitle').classList.add('filter_active');
	} else {
		thisElement.classList.remove('filter_active');
		document.querySelector('.malist_filtertitle').classList.remove('filter_active');
	}
	const listeHTML = document.querySelectorAll('.ma-select-item');
	listeHTML.forEach((maHTML) => {
		// Falls der Mitarbeiter in der Bereich Liste ist... oder schon markiert ist...
		if (listeBereichFilter.some((maBereich) => maHTML.id === maBereich._id) || maHTML.querySelector('[aria-label="ma-control-check"]').checked) {
			// einblenden
			maHTML.classList.remove('hidden');
		} else {
			// ausblenden
			maHTML.classList.add('hidden');
		}
	});
	document.getElementById('ma-suchfeld').value = '';
	colorMaList();
}

/**
 * Filtert die Mitarbeiter ob die Mitarbeiter in dem aktuellen Monat ihr Eintritt/Austritt Datum haben.
 * Angehakte Mitarbeiter bleiben sichtbar.
 * @param {HTMLElement} thisElement Select Eintritt/Austritt Monat
 */
async function filterEinAustritte(thisElement) {
	const typ = thisElement.value;
	// todo: Könnte man an der stelle auch optimieren, bzw. nicht neu laden...
	const zeitraum = getAktuellerZeitraum();
	const listeJson = await stammdatenService.holeMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette, zeitraum);
	let listeFilter;
	switch (typ) {
		case 'eintritt':
			listeFilter = listeJson.filter((ma) => dayjs(ma.Eintrittsdatum).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'austritt':
			listeFilter = listeJson.filter((ma) => dayjs(ma.Austrittsdatum).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'befristung':
			listeFilter = listeJson.filter((ma) => dayjs(ma.BefristetBis).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'probezeit':
			listeFilter = listeJson.filter((ma) => dayjs(ma.ProbezeitBis).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'aufenthalt':
			listeFilter = listeJson.filter((ma) => dayjs(ma.AufenthaltsgenehmigungBis).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'arbeitserlaubnis':
			listeFilter = listeJson.filter((ma) => dayjs(ma.ArbeitserlaubnisBis).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'studenten':
			listeFilter = listeJson.filter((ma) => dayjs(ma.Immatrikulationsbescheinigung).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		case 'vollstaendigeMA':
			listeFilter = listeJson.filter((ma) => !ma.HasErrors);
			break;
		case 'unvollstaendigeMA':
			listeFilter = listeJson.filter((ma) => ma.HasErrors);
			break;
		case 'ungeleseneMA':
			listeFilter = listeJson.filter((ma) => ma.NeueNachricht);
			break;
		case 'ueberlaengeMA':
			listeFilter = listeJson.filter((ma) => ma.Ueberlaenge);
			break;
		case 'berechneteMA':
			listeFilter = listeJson.filter((ma) => ma.Berechnet || ma.Abgeschlossen);
			break;
		case 'unberechneteMA':
			listeFilter = listeJson.filter((ma) => !ma.Berechnet && !ma.Abgeschlossen);
			break;
		case 'abgeschlosseneMA':
			listeFilter = listeJson.filter((ma) => ma.Abgeschlossen);
			break;
		case 'unabgeschlosseneMA':
			listeFilter = listeJson.filter((ma) => !ma.Abgeschlossen);
			break;
		case 'abweichendeMA':
			listeFilter = listeJson.filter((ma) => ma.Abweichung);
			break;
		case 'alle':
			listeFilter = listeJson.filter((ma) => dayjs(ma.Eintrittsdatum).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.Austrittsdatum).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.BefristetBis).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.ProbezeitBis).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.AufenthaltsgenehmigungBis).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.ArbeitserlaubnisBis).isSame(window.myVars.aktuellesMonatsDatum, 'month')
				|| dayjs(ma.Immatrikulationsbescheinigung).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
			break;
		// case 'kurzfristige': TODO
		// 	listeFilter = listeJson.filter((ma) => dayjs(ma.).isSame(window.myVars.aktuellesMonatsDatum, 'month'));
		// 	break;
		default:
			listeFilter = listeJson;
			break;
	}
	if (typ !== '') {
		document.getElementById('ma-filter-pgs').value = '';
		document.getElementById('ma-filter-pgs').classList.remove('filter_active');
		document.getElementById('ma-filter-bereich').value = '';
		document.getElementById('ma-filter-bereich').classList.remove('filter_active');
		thisElement.classList.add('filter_active');
		document.querySelector('.malist_filtertitle').classList.add('filter_active');
	} else {
		thisElement.classList.remove('filter_active');
		document.querySelector('.malist_filtertitle').classList.remove('filter_active');
	}
	const listeHTML = document.querySelectorAll('.ma-select-item');
	listeHTML.forEach((maHTML) => {
		// Falls der Mitarbeiter in der PGS Liste ist... oder schon markiert ist...
		if (listeFilter.some((maFilter) => maHTML.id === maFilter._id) || maHTML.querySelector('[aria-label="ma-control-check"]').checked) {
			// einblenden
			maHTML.classList.remove('hidden');
		} else {
			// ausblenden
			maHTML.classList.add('hidden');
		}
	});
	document.getElementById('ma-suchfeld').value = '';
	colorMaList();
}

function resetValidationMessages() {
    const elements = document.querySelectorAll('.validation-msg');
    elements.forEach((element) => { element.innerHTML = ''; });
}

/**
 * Checkt ob der Mitarbeiter bereits einen:
 * - identischen Urlaubsantrag
 * - oder einen genehmigten und überlappenden Urlaubsantrag hat
 * @param {object} aktuellerMA
 * @param {dayjs} urlaubVon
 * @param {dayjs} urlaubBis
 * @param {dayjs} urlaubID wird nur benötigt, wenn wir einen bestehenden Urlaubsantrag überprüfen
 * @returns index des kollidierenen Urlaubantrags
 */
function checkUrlaubsantragKollisionen(aktuellerMA, urlaubVon, urlaubBis, urlaubID = undefined) {
	const urlaubsantraege = aktuellerMA.Beschaeftigung[0].Urlaubsantraege;
	const gibtKollisionen = urlaubsantraege.findIndex((antrag) => {
		// überspringe den Urlaubsantrag, den wir kontrollieren (falls wir einen bestehenden Urlaubsantrag checken)
		if (urlaubID && antrag._id === urlaubID) {
			return false;
		}
		// gleicher anfang/ende
		if (dayjs(antrag.UrlaubVon).isSame(urlaubVon, 'day') && dayjs(antrag.UrlaubBis).isSame(urlaubBis, 'day')) {
			return true;
		}
		// überlappendes Datum:
		if (antrag.UrlaubStatus === 'genehmigt'
			&& (dayjs(antrag.UrlaubVon).isBetween(urlaubVon, urlaubBis, 'day', '[]')
				|| dayjs(antrag.UrlaubBis).isBetween(urlaubVon, urlaubBis, 'day', '[]')
				|| urlaubVon.isBetween(dayjs(antrag.UrlaubVon), dayjs(antrag.UrlaubBis), 'day', '[]')
				|| urlaubBis.isBetween(dayjs(antrag.UrlaubVon), dayjs(antrag.UrlaubBis), 'day', '[]'))
			) {
				return true;
			}
		return false;
	});
	// Dialog Box updaten
	const urlaubsantraegeMA = aktuellerMA.Beschaeftigung[0].Urlaubsantraege;
	if (gibtKollisionen >= 0) {
		const alertText = `Es bestehen bereits genehmigte, oder identsiche Urlaubsanträge für den Urlaubszeitraum, den Sie gerade eintragen möchten: <span style="font-weight:bold;">${dayjs(urlaubsantraegeMA[gibtKollisionen].UrlaubVon).format('DD.MM.YYYY')} - ${dayjs(urlaubsantraegeMA[gibtKollisionen].UrlaubBis).format('DD.MM.YYYY')}</span>`;
		document.getElementById('zeiten-urlaub-kollision-text').innerHTML = alertText;
	}
	return gibtKollisionen;
}

function resetDialogInput(dialogID) {
	const dialog = document.getElementById(dialogID);
	const inputArray = [...dialog.querySelectorAll('input')];
	inputArray.forEach((input) => {
		switch (input.type) {
			case 'text':
				input.value = '';
				break;
			case 'number':
				input.value = 0;
				break;
			case 'checkbox':
				input.checked = false;
				break;
			default:
				break;
		}
	});
}

/**
 * Bei abgeschlossenen Journaldaten wollen wir die Oberfläche sperren lassen.
 */
function sperreOberflaeche(monatAbgeschlossen) {
	const container = document.querySelector('.ma-container');
	const arraySperrelemente = [...container.querySelectorAll('button:not(.accordion), select, input:not(.zeiten-legende-auswahl>input)')];
	if (document.getElementById('abgeschlossen-banner')) {
		document.getElementById('abgeschlossen-banner').remove();
	}
	if (monatAbgeschlossen) {
		arraySperrelemente.forEach((elem) => elem.disabled = true);
		window.myVars.zeitenEditable = false;
		container.insertAdjacentHTML('beforebegin', '<div id="abgeschlossen-banner">Abgeschlossene Journaldaten: Eingabe nur im offenen Monat möglich</div>');
	} else {
		// manche Elemente müssen wir beim "Entsperren" überspringen.
		arraySperrelemente.filter((elem) => !elem.classList.contains('skip-enable') && !elem.classList.contains('skip-mod')).forEach((elem) => elem.disabled = false);
		window.myVars.zeitenEditable = true;
	}
}

/**
 * Eine Art von Array Klasse, die Callback Funktionen aufruft, wenn sich das Array ändert.
 */
function ObservableArray() {
	let arr = [];
	let callback = null;
  
	// Setter for the array that triggers a callback
	function setArray(newArray) {
	  arr = newArray;
	  if (callback) {
		callback(arr);
	  }
	}
  
	// Setter for the callback function
	function setCallback(newCallback) {
	  callback = newCallback;
	}
	// Push method to add an element to the array
	function push(element) {
		arr.push(element);
		if (callback) {
		  callback(arr);
		}
	  }
	
	// Remove method to delete an element by index
	function remove(index) {
		if (index >= 0 && index < arr.length) {
			arr.splice(index, 1);
			if (callback) {
				callback(arr);
			}
		}
	}	

	// Replace method to replace an element at a specific index
	function replace(index, element) {
		if (index >= 0 && index < arr.length) {
			arr[index] = element;
			if (callback) {
			callback(arr);
			}
		}
	}

	return {
	  get: () => arr,
	  set: setArray,
	  setCallback,
	  push,
	  remove,
	  replace
	};
  }

function getSelectedMitarbeiterIDs() {
	const maList = [];
	// wir nehmen alle angehakten Mitarbeiter, ansonsten den aktuell ausgewählten Mitarbeiter.
    const listeHTML = document.querySelectorAll('.ma-select-item');    
    const markedMA = Array.from(listeHTML).filter((maHTML) => maHTML.querySelector('[aria-label="ma-control-check"]').checked);
	// sammle alle angehakten MitarbeiterIDs
	if (markedMA.length >= 1) {
		markedMA.forEach((ma) => {
			maList.push(ma.id);
		});
	} else if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		// wenn wir keine Mitarbeiter angehakt haben, schicken wir nur den aktuell ausgwählten.
		maList.push(stammdatenService.aktuellerMitarbeiter._id);
	} 
	return maList;
}

export {
	displayValues,
	collectValues,
	debounce,
	fuelleSelectOptionen,
	erstelleOptionGroup,
	displayArray,
	neueArrayRow,
	addInputRow,
	holeQualifikationenMitarbeiter,
	holePostenMitarbeiter,
	holeEinsatzorteMitarbeiter,
	convertNumberToEuro,
	formatNumber2Decimals,
	toggleLoader,
	erstelleMitarbeiterListe,
	hightlightMA,
	colorMaList,
	setMenuActive,
	setMenuInactive,
	fuelleBetriebSelect,
	resetValidationMessages,
	checkUrlaubsantragKollisionen,
	resetDialogInput,
	sperreOberflaeche,
	ObservableArray,
	getSelectedMitarbeiterIDs,
	getAktuellerZeitraum
};
